const ErrorNotify = {
  setError(data) {
    if (data) {
      if (data.data.hasOwnProperty('non_field_errors')) {
        for (let i = 0; i < data.data.non_field_errors.length; i++) {
          data.ob.$vs.notify({
            text: `${data.data.non_field_errors[i]}`,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-x-circle'
          })
        }
      } else {
        let errores = Object.values(data.data)
        let key = Object.keys(data.data)
        for (let i = 0; i < errores.length; i++) {
          data.ob.$vs.notify({
            text: `${data.ob.$t('errors.' + key[i])} ${errores[i]}`,
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-x-circle'
          })
        }
      }
    } else {
      data.ob.$vs.notify({
        text: `${data.ob.$t('bad_request_errors.general')}`,
        color: 'danger',
        iconPack: 'feather',
        icon: 'icon-x-circle'
      })
    }
    
  },
  formsErrors(data) {
    const requestBody = data.data ? data.data : { status: 500 }
    const component = data.ob
    // Notification Function
    const notify = (message) => {
      component.$vs.notify({
        text: message,
        color: 'danger',
        iconPack: 'feather',
        icon: 'icon-x-circle'
      })
    }
    if (requestBody.status >= 500) notify(`${component.$t('bad_request_errors.general')}`) // Check Server Side Error
    else if (requestBody.data.hasOwnProperty('non_field_errors')) 
      requestBody.data.non_field_errors.forEach(message => notify(`${message}`)) // Check Login Error
    else if (requestBody.status == 400) {
      const errors = requestBody.data
      for (const error in errors) {
        errors[error].forEach(message => {
          const split = message.split(':')
          let errorMessage = ''
          if (split.length == 2)
            errorMessage = `
              ${component.$t('bad_request_errors.' + split[0])} ${component.$t('bad_request_errors.' + split[1])}
            `
          else errorMessage = `${component.$t('bad_request_errors.' + error)}: ${errors[error]}`
          notify(errorMessage)
        })
      } // Check Bad Request Errors
    }
    else notify('General Error') // Notify an error in general
  }
}
export default ErrorNotify
