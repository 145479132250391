<template>
  <div :id="classid">
    <div v-if="dataInfo.company != undefined && dataInfo.company.length > 0">
      <a :href="`https://api.whatsapp.com/send?phone=${dataInfo.company[0].mobile_phone}`" target="_blank"
        rel="noopener noreferrer">
        <img class="img-fixed" :src='require(`@/assets/images/logo/whatsapp.png`)' alt="">
      </a>
    </div>
    <vs-sidebar class="items-no-padding" hidden-background="hidden-background" position-left="position-left"
      v-model="isSidebarActiveLocal" click-not-close="click-not-close">
      <div :class="`flex flex-col ${bodyCard}`">
        <div class="customizer-header flex items-center justify-between px-6">
          <div>
            <h4 class="Informacin-Domicilio">{{ $t("mapview.listado_services") }}</h4>
            <p class="Aqu-va-el-texto-descriptivo-de-la-seccin">¡Rastrea y hazle seguimiento a tu entrega!</p>
          </div>
          <feather-icon class="cursor-pointer" :icon="iconClass" @click.stop="ocultar()"></feather-icon>
        </div>
        <div class="px-6">
          <label class="vs-select--label Ingresa-tu-codigo">{{ $t('seguimiento.sigue_codigo') }}</label>
          <div class="flex justify" v-if="!setVisibleMobile()">
            <vs-input class="w-full mr-5" v-validate="'required'" name="code" v-model="code"></vs-input>
            <vs-button class="botones-search-2 " :disabled="show_button" @click="setSave">{{ $t('seguimiento.search2')
            }}</vs-button>
          </div>
          <div class="block justify" v-else>
            <vs-input class="w-full mr-5 mb-4" v-validate="'required'" name="code" v-model="code"></vs-input>
            <vs-button class="Botones-search-movil w-full" :disabled="show_button" @click="setSave">{{
              $t('seguimiento.search2') }}</vs-button>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
        </div>
        <!-- <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12" vs-lg="12" vs-sm="12" vs-xs="12">
          <vs-button color="primary" icon="search" @click="searchVisitas()">Buscar servicio</vs-button>
        </vs-col> -->
        <vs-divider></vs-divider>
        <div class="px-6 mb-4" v-if="setVisibleMobile()">
          <h4 class="Informacin-del-domicilio">{{ $t("mapview.listado_services") }}</h4>
          <p class="Aqu-va-el-texto-descriptivo-de-la-seccin">¡Rastrea y hazle seguimiento a tu entrega! </p>
          <vs-button color="none" class="Frame-9 w-full" @click="ocultar()">{{ $t('seguimiento.mostrar_ruta')
          }}</vs-button>
        </div>
        <div class="">
          <div id="card-body-2" class="px-6">
            <vx-card class="cardx mb-2" v-if="dataInfo != null">
              <h3 class="Datos-de-la-entrega">Datos de la entrega</h3>
              <hr style="width: 100%;border-top: 1px solid #6f6f768c;">
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" class="mt-5">
                  <span class="span-label-1">
                    {{ dataInfo.fecha_create }}
                  </span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" class="mt-5">
                  <p class="Aceptado">
                    {{ capitalizarPrimeraLetra(dataInfo.estado.toLowerCase()) }}
                  </p>
                </vs-col>
              </vs-row>
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
                <vs-col vs-type="inline-grid" vs-justify="left" vs-align="left" vs-w="12" class="mt-5">
                  <span class="span-label-1">
                    {{ dataInfo.novedad }}
                  </span>
                  <label class="label-1">{{ $t('seguimiento.novedades') }}</label>
                </vs-col>
                <vs-col vs-type="inline-grid" vs-justify="left" vs-align="left" vs-w="12" class="mt-5">
                  <span class="span-label-1">
                    {{ dataInfo.guia }}
                  </span>
                  <label class="label-1">{{ $t('seguimiento.n_entrega') }}</label>
                </vs-col>
                <vs-col vs-type="inline-grid" vs-justify="left" vs-align="left" vs-w="12" class="mt-5">
                  <span class="span-label-1">
                    {{ dataInfo.ciudad }}
                  </span>
                  <label class="label-1">{{ $t('seguimiento.ciudad') }}</label>
                </vs-col>
              </vs-row>
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
                <vs-col vs-type="inline-grid" vs-justify="left" vs-align="left" vs-w="12" class="mt-5">
                  <span class="span-label-1">
                    {{ dataInfo.cedi }}
                  </span>
                  <label class="label-1">{{ $t('seguimiento.desde') }}</label>
                </vs-col>
              </vs-row>
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
                <vs-col vs-type="inline-grid" vs-justify="left" vs-align="left" vs-w="12" class="mt-5">
                  <span class="span-label-1">
                    {{ dataInfo.nombre_cliente }}
                  </span>
                  <label class="label-1">{{ $t('seguimiento.se_entrega') }}</label>
                </vs-col>
              </vs-row>
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
                <vs-col vs-type="inline-grid" vs-justify="left" vs-align="left" vs-w="12" class="mt-5">
                  <span class="span-label-1">
                    {{ dataInfo.direccion }}
                  </span>
                  <label class="label-1">{{ $t('seguimiento.direccion') }}</label>
                </vs-col>
              </vs-row>
              <div v-if="show_delivery_time">
                <vs-divider></vs-divider>
                <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
                  <vs-col vs-type="inline-grid" vs-w="12" class="mt-5">
                    <span class="span-label-1">
                      {{ Math.ceil(minute / 1.5) }} Minutos
                    </span>
                    <label class="label-1">{{ $t('seguimiento.time_stimate') }}</label>
                  </vs-col>
                </vs-row>
              </div>
            </vx-card>
          </div>
          <div id="card-body-3" class="px-6">
            <vx-card class="cardx mb-5" v-if="dataInfo != null">
              <h3 class="Datos-del-conductor">Datos del conductor</h3>
              <hr style="width: 100%;border-top: 1px solid #6f6f768c;">
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
                <vs-col vs-type="inline-grid" vs-justify="left" vs-align="left" vs-w="6" class="mt-5">
                  <span class="span-label-1">
                    {{ dataInfo.responsable }}
                  </span>
                  <label class="label-1">{{ $t('seguimiento.conductor') }}</label>
                </vs-col>
                <vs-col vs-type="inline-grid" vs-justify="center" vs-align="center" vs-w="6" class="mt-5 border-ll">
                  <span class="span-label-1">
                    {{ dataInfo.placa }}
                  </span>
                  <label class="label-1">{{ $t('seguimiento.placa') }}</label>
                </vs-col>
              </vs-row>
            </vx-card>
          </div>
        </div>
        <div class="flex flex-wrap justify-center">
          <vs-button :disabled="setVisibleBoton(dataInfo.foto)" class="botones-evidencias" @click="showEvidences">{{
            $t('seguimiento.evidencia') }}</vs-button>
        </div>
      </div>
    </vs-sidebar>
    <show-images v-if=dataInfo.foto ref="imagesShow" :popupActivo=popupActivo :fotos=dataInfo.foto
      @closePopup="popupActivo = false"></show-images>
  </div>
</template>

<script>
import ShowImages from "@/components/towerControl/ShowPictures"
import moment from "moment"
import "moment-timezone"
import { isMobile } from 'mobile-device-detect'

const NAME = "ListServices";

export default {
  name: NAME,
  components: {
    ShowImages
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    minuteRouter: {
      type: Number,
      required: false
    },
    dataInfoArray: {
      type: Object,
      required: false
    },
    statusArray: {
      type: Array,
      required: false
    },
    show_delivery_time: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      show_button: false,
      code: this.$route.query.guia,
      activaClass: false,
      iconClass: 'ChevronUpIcon',
      popupActivo: false,
      popupActivo2: false,
      color: 'blue',
      classid: 'theme-customizer-create-services',
      bodyCard: 'card-body-10',
      ShowEvidences: true
    };
  },
  computed: {
    dataInfo: {
      get() {
        return this.dataInfoArray;
      },
      set(val) {
        return val;
      },
    },
    status: {
      get() {
        return this.statusArray
      },
      set(val) {
        return val
      }
    },
    minute: {
      get() {
        return this.minuteRouter
      },
      set(val) {
        return val
      }
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar")
        }
      },
    },

  },
  methods: {
    setVisibleBoton(foto) {
      return foto != null ? false : true
    },
    setVisibleMobile() {
      return isMobile ? true : false
    },
    showEvidences() {
      this.popupActivo = !this.popupActivo
    },
    setTimezone(val) {
      return moment.tz(val.replace('T', ' ').replace('7Z', ''), "America/Bogota").format('HH:mm')
    },
    capitalizarPrimeraLetra(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    DeactivateButton(val) {
      this.show_button = val
    },
    setSave() {
      this.DeactivateButton(true)
      this.$emit("searchServi", this.code)
      window.location.reload()

    },
    ocultar() {
      this.activaClass = !this.activaClass
      if (this.activaClass) {
        this.classid = 'theme-customizer-create-services-2'
        this.bodyCard = ''
        this.iconClass = 'ChevronDownIcon'
      } else {
        this.classid = 'theme-customizer-create-services'
        this.bodyCard = 'card-body-10'
        this.iconClass = 'ChevronUpIcon'
      }
    },
    close(val) {//eslint-disable-line
      this.popupActivo2 = false;
    },
    searchVisitas() {
      this.isSidebarActiveLocal = false
      this.$emit("closeSidebar2", this.isSidebarActiveLocal);
    }
  },
};

</script>
<style>
.img-fixed {
  display: block;
  right: 20px;
  bottom: 40px;
  position: fixed;
  z-index: 9999;
}

@media only screen and (max-width: 600px) {
  .img-fixed {
    width: 50px;
    right: 5px;
    bottom: 10px;
  }
}

.Frame-9 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 40px 7px 44px 15px;
  padding: 10px;
  border-radius: 6px;
  border: solid 2px #1a73e8;
  background-color: #ffffff;
}

#theme-customizer-create-services>div>div>div>div.flex.flex-col.card-body-10>div.px-6.mb-4>button>span.vs-button-text.vs-button--text {
  color: #1a73e8
}

.theme-dark .Frame-9 {
  border: solid 2px #fdd402;
}

.Informacin-del-domicilio {
  flex-grow: 0;
  margin: 0 102px 0 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c2c;
}

.botones-evidencias {
  height: 38px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 1.8px 0.3px 0.1px 19px;
  padding: 8px;
  border-radius: 6px;
  background-color: #fdd402 !important;
}

.vs-button:disabled {
  background-color: #f0f0f0 !important;
}

.span-label-1 {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c2c;
}

.label-1 {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6f6f76;
}

.Ingresa-tu-codigo {
  margin: 0 7px 0.1px 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c2c;
}

.botones-search-2 {
  width: 200px;
  height: 50px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 17.8px 0 25.5px 2.5px;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fdd402;
  flex-grow: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c2c;
}

.vs-inputx {
  height: 50px;
  margin: 1px 0 0;
  border-radius: 6px;
  border: solid 1px #6f6f76 !important;
  background-color: #f0f0f0;
}

.Aqu-va-el-texto-descriptivo-de-la-seccin {
  flex-grow: 0;
  margin: 3.4px 0 0;
  padding: 0.1px 21.9px 20px 0.1px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Informacin-Domicilio {
  flex-grow: 0;
  margin: 0 12px 0 0;
  padding: 20.2px 5.8px 0.5px 0.2px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c2c;
}

.Rectangle-3-codigo {
  width: 248px;
  height: 50px;
  margin: 1px 0 0;
  padding: 12.7px 106.5px 6.9px 10px;
  border-radius: 6px;
  border: solid 1px #6f6f76;
  background-color: #f0f0f0;
}

.Datos-de-la-entrega {
  flex-grow: 0;
  margin: 13.3px 0.4px 2.5px 0;
  opacity: 0.9;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6f6f76;
}

#card-body-2 .vx-card__body {
  width: 408px;
  height: auto;
  flex-grow: 0;
  background-color: #f0f0f0;
  border-radius: 9px 9px 0px 0px;
}

#card-body-3 .vx-card__body {
  width: 408px;
  height: auto;
  flex-grow: 0;
  background-color: #f0f0f0;
  border-radius: 0px 0px 9px 9px;
}

.theme-dark h3 {
  color: white;
}

.theme-dark .span-label-1 {
  color: white;
}

.cardx {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.Datos-del-conductor {
  flex-grow: 0;
  opacity: 0.9;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6f6f76;
}

.Botones-search-movil {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 14px 6px 53px 16px;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fdd402;
}

.Aceptado {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #718b68;
}

.theme-dark .Aceptado {
  color: #718b68;
}

.theme-dark .border-ll {
  border-left: 1px solid #eeeeee;
}

.border-ll {
  border-left: 1px solid #a9a9ad;
}

.card-body-10 {
  height: calc(97vh - 5em);
  overflow: auto;
}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  #theme-customizer-create-services-2 .vs-sidebar {
    position: fixed;
    z-index: 52000;
    margin-top: 4.8em;
    margin-left: 0px;
    width: auto;
    height: 120px !important;
    max-width: 100vw;
    background-color: #ffffff;
  }
}


/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  #theme-customizer-create-services-2 .vs-sidebar {
    position: fixed;
    z-index: 52000;
    margin-top: 4.8em;
    margin-left: 0px;
    width: auto;
    height: 120px !important;
    max-width: 100vw;
    background-color: #ffffff;
  }

  .Informacin-Domicilio {
    font-size: 22px;
    padding-bottom: 10px;
  }

  #card-body-2 .vx-card__body {
    width: 100%;
    height: auto;
  }

  #card-body-3 .vx-card__body {
    width: 100%;
    height: auto;
  }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  #theme-customizer-create-services-2 .vs-sidebar {
    position: fixed;
    z-index: 52000;
    margin-top: 4.8em;
    margin-left: 0px;
    width: auto;
    height: 91px !important;
    max-width: 100vw;
    background-color: #ffffff;
  }

  .Informacin-Domicilio {
    font-size: 22px;
    padding-bottom: 10px;
  }

  #card-body-2 .vx-card__body {
    width: 100%;
    height: auto;
  }

  #card-body-3 .vx-card__body {
    width: 100%;
    height: auto;
  }
}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  #theme-customizer-create-services-2 .vs-sidebar {
    position: fixed;
    z-index: 52000;
    margin-top: 4.8em;
    margin-left: 0px;
    width: auto;
    height: 91px !important;
    max-width: 120vw;
    background-color: #ffffff;
  }

  .Informacin-Domicilio {
    font-size: 22px;
    padding-bottom: 10px;
  }

  #card-body-2 .vx-card__body {
    width: 100%;
    height: auto;
  }

  #card-body-3 .vx-card__body {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 411px) and (max-width: 731px) {
  #card-body-2 .vx-card__body {
    width: 100%;
    height: auto;
  }

  #card-body-3 .vx-card__body {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1366px) {
  .card-body-10 {
    height: calc(97vh - 5em);
    overflow: auto;
  }
}

@media only screen and (min-width: 741px) and (max-width: 1299px) {
  .card-body-10 {
    height: calc(100vh - 5em);
    overflow: auto;
  }
}

@media only screen and (min-width: 384px) and (max-width: 640px) {
  #card-body-2 .vx-card__body {
    width: 100%;
    height: auto;
  }

  #card-body-3 .vx-card__body {
    width: 100%;
    height: auto;
  }
}

.vs-button--text {
  font-weight: 600;
}
</style>
<style lang="stylus">
img {
  display: inline-block !important ;
}
#theme-customizer-create-services-2 {
  .vs-sidebar {
    position: fixed;
    z-index: 52000;
    margin-top: 0em;
    margin-left: 65px;
    width: 450px;
    height: 95px !important;
    max-width: 200vw;
    background-color: #ffffff;
  }
}
#theme-customizer-create-services {
  .vs-sidebar {
    position: fixed;
    z-index: 52000;
    margin-top: 0em;
    margin-left: 65px;
    width: 450px;
    height: 74px;
    max-width: 200vw;
    background-color: #ffffff;
  }
}

@media only screen and (max-width: 600px) {
  .card-body-10 {
    height: calc(88vh - 5em);
    overflow: auto;
  }
  #theme-customizer-create-services-2 {
    .vs-sidebar {
      position: fixed;
      z-index: 52000;
      margin-top: 4.8em;
      margin-left: 0px;
      max-width: 100vw;
      background-color: #ffffff;
    }
  }
  #theme-customizer-create-services {
    .vs-sidebar {
      position: fixed;
      z-index: 52000;
      margin-top: 4.8em;
      margin-left: 0px;
      max-width: 100vw;
      background-color: #ffffff;
    }
  }

  #card-body .vx-card__body {
    height: 280px !important;
  }
}

#card-body .vx-card__body {
  height: 200px;
}

#cad-color .vs-card--header {
  background: #fdd402;
}
#theme-customizer-create-services > div > div > div > div > div.vx-card > div.vx-card__collapsible-content.vs-con-loading__container > div {
  height: calc(87.9vh - 5em);
  overflow: auto;
}
.theme-dark #theme-customizer-create-services .vs-sidebar {
  background-color: #080808;
}
.theme-dark #theme-customizer-create-services-2 .vs-sidebar {
  background-color: #080808;
}
.theme-dark #card-body-2 .vx-card__body {
  background-color: #2c2c2c;
}
.theme-dark #card-body-3 .vx-card__body {
  background-color: #2c2c2c;
}
.theme-dark  #theme-customizer-create-services > div > div > div > div.flex.flex-col.card-body-10 > div.customizer-header.flex.items-center.justify-between.px-6 > span > svg {
  color: white;
}
.theme-dark #theme-customizer-create-services-2 > div > div > div > div.flex.flex-col > div.customizer-header.flex.items-center.justify-between.px-6 > span > svg {
  color: white;
}
</style>