<template lang="pug">
vs-dropdown.cursor-pointer(
  vs-custom-content='' 
  vs-trigger-click=''
)
  feather-icon.cursor-pointer.mt-1.ml-4(
    icon='BellIcon' 
    :badge='unreadNotifications.length'
  )
  vs-dropdown-menu.notification-dropdown.dropdown-custom
    .notification-top.text-center.p-5.bg-primary.text-white
      h3.text-white {{ unreadNotifications.length }} New
      p.opacity-75 App Notifications
    VuePerfectScrollbar.scroll-area--nofications-dropdown.p-0.mb-10(
      ref='mainSidebarPs' 
      :settings='settings'
    )
      ul.bordered-items
        li.flex.justify-between.px-4.py-4.notification.cursor-pointer(
          v-for='ntf in unreadNotifications' :key='ntf.index'
        )
          .flex.items-start
            feather-icon(
              :icon='ntf.icon' 
              :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"
            )
            .mx-2
              span.font-medium.block.notification-title(
                :class='[`text-${ntf.category}`]'
              ) {{ ntf.title }}
              small {{ ntf.msg }}
          small.mt-1.whitespace-no-wrap {{ elapsedTime(ntf.time) }}
    .checkout-footer.fixed.pin-b.rounded-b-lg.text-primary.w-full.p-2.font-semibold.text-center.border.border-b-0.border-l-0.border-r-0.border-solid.border-grey-light.cursor-pointer
      span View All Notifications

</template>

<script>

import VuePerfectScrollbar from "vue-perfect-scrollbar"

// Constantes 
let COMPONENT_NAME="Notifications"

export default {
  name: COMPONENT_NAME,
  components: {
    VuePerfectScrollbar
  },
  props: {
    unreadNotifications: {
      type: Array,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  methods: {
    elapsedTime(startTime) {
      let x = new Date(startTime)
      let now = new Date()
      var timeDiff = now - x
      timeDiff /= 1000

      var seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      var minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      var hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      var days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      var years = timeDiff

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago"
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago"
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago"
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago"
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? `${seconds} sec ago` : "just now")
      }

      return "Just Now"
    }
  }
}
</script>
