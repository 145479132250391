<template lang="pug">
vs-popup(
  id="popupSearchServiceForm"
  classContent="popup-example"
  :title="$t('mapview.inf_services')"
  :active.sync="popup"
)
  div(
    class="vx-row"
  )
    div(
      class="vx-col sm:w-1/1 w-full mb-2"
    )
      vs-input.w-full.inputx.sm-input(
        name="code"
        v-validate="'required'"
        :data-vv-as="$t('mapview.code')"
        :label-placeholder='$t("mapview.ping")'
        v-model="code"
        v-on:keyup.enter="setSave"
      )
      span.text-danger.text-sm(
        v-if="errors.has('code')"
      ) {{ errors.first('code') }}
  vs-row
    vs-col(
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-sm="12"
      vs-xs="12"
    )
      vs-button.mt-6.block.offset-3(
        color="primary"
        type="filled"
        :disabled="isSaved"
        @click="setSave"
      ) {{$t('mapview.search')}}
</template>

<script>
import HTTP from "@/http-common"
import ErrorNotify from "@/components/errors/Errors"
import i18n from "@/i18n.js"

export default {
  props: {
    popupActivo: {
      type: Boolean,
      required: false
    },
  },
  data() {
    return {
      i18n: i18n,
      code: '',
      infoData: null,
      isSaved: false
    }
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup")
        }
      }
    },
  },
  mounted() {
    if (this.$route.query.hasOwnProperty('guia')) {
      this.code = this.$route.query.guia
      this.setSave()
    }
  },
  methods: {
    clear() {
      return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
      );
    },
    setSave() {
      this.isSaved = true
      this.searchServices(this.code)
    },
    searchServices(code) {
      this.$validator.validateAll().then(result => {
        if (result) {
          let url = `services/search/`
          let data = {
            code: code
          }
          HTTP.setHeader(true)
          HTTP.query(url, data)
            .then((response) => {
              this.infoData = response.data.data
              if (this.infoData.length > 0) {
                this.$emit("infoData", this.infoData, code)
                this.popup = false
              } else {
                this.$vs.notify({
                  text: this.$t("mapview.no_search"),
                  color: "#64cc52",
                  iconPack: "feather",
                  icon: "icon-check-circle"
                })
              }
                this.isSaved = false
            })
            .catch(response => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this
              })
              this.isSaved = false
            })
        } else {
          this.isSaved = false
        }
      })
    },
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupSearchServiceForm")
    if (elem) {
      elem.parentNode.removeChild(elem)
    }
  }
}
</script>

<style>
#popupSearchServiceForm .vs-popup {
  width: 350px;
}
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
