<template>
  <vs-popup id="popupSearchServiceForm-10" classContent="popup-example" :title="$t('seguimiento.servicio_evi')" :active.sync="popup">
    <Tinybox
        v-model="index"
        :images="images"
        :loop="loopCheckbox"
        :no-thumbs="!thumbsCheckbox"
    ></Tinybox>
    <div class="flex flex-wrap justify-left">
      <img
        v-for="(img, idx) in images"
        class="open-tinybox img-10"
        width=240
        height=220
        :src="img"
        :key="idx"
        @click="index = idx"
      >
    </div>
    <div class="flex flex-wrap justify-center" >
      <vs-button class="mt-6 block Botones-aceptar" color="primary" type="filled" @click="closep">{{$t('seguimiento.cerrar')}}</vs-button>
    </div>
  </vs-popup>
</template>

<script>
import Tinybox from "vue-tinybox"
import i18n from "@/i18n.js"

export default {
  components: {
    Tinybox
  },
  props: {
    popupActivo: {
      type: Boolean,
      required: false
    },
    fotos: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      i18n: i18n,
      index: null,
      loopCheckbox: false,
      thumbsCheckbox: true,
    }
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup")
        }
      }
    },
    images: {
      get() {
        return this.fotos
      },
      set(val) {
        return val
      }
    },
  },
  methods: {
    closep() {
      this.popup = false
    }
  }
}
</script>

<style>
@media only screen and (max-width: 600px) {
  .Botones-cerrar {
    width: unset !important;
  }
  .Botones-aceptar {
    width: unset !important;
  }
}
#popupSearchServiceForm-10 .vs-popup {
  width: 800px;
  height: auto;
}
.img-10 {
  margin: 5px 0px 0px 13.9px;
}
.Botones-cerrar {
  width: 200px;
  height: 39px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: solid 2px #fdd402;
  background-color: #ffffff;
}
.Botones-aceptar {
  width: 200px;
  height: 39px;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background-color: #fdd402;
}
.theme-dark #popupSearchServiceForm-10 > div.vs-popup > div > div.flex.flex-wrap.justify-center > button.vs-component.vs-button.mr-10.mt-6.block.Botones-cerrar.vs-button-null.vs-button-filled > span.vs-button-text.vs-button--text {
  color: white
}
.theme-dark #theme-customizer-create-services > div > div > div > div.flex.flex-col.card-body-10 > div.px-6.mb-4 > button > span.vs-button-text.vs-button--text {
  color: #fdd402
}
.theme-dark .vs-popup--title h3 {
  color: white
}
.theme-dark .vs-popup--title h3 {
  color: white
}
.theme-dark .Botones-cerrar .vs-button--text {
  color: #fdd402
}
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
