<template lang="pug">
  div(
    id="divMenuLogin"
    class="the-navbar__user-meta flex items-center sm:ml-5 ml-2"
  )
    div(
      style="display:flex !important"
      class="text-right leading-tight hidden sm:block flex-col"
    )
      p(
        class="font-semibold"
        v-if="currentUser"
      ) {{ currentUser.first_name }} {{ currentUser.last_name }}
      small(
        class="mb-1"
        v-if="currentUser.company_id"
      ) {{ currentUser.company_id.company }}
      font(
        size="1"
        v-if="currentRol"
      ) {{ currentRol[0] }}
    vs-dropdown(
      class="cursor-pointer"
    )
      div(
        class="con-img ml-3"
      )
        img(
          :src="setImage(currentUser.avatar)"
          alt
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block"
        )
      vs-dropdown-menu(
        style="width: 15rem;"
      )  
        ul
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push({'name': 'Profile'})"
          )
            feather-icon(
              icon="UserIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('profile') }}
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push({'name': 'ResetPassword'})"
          )
            feather-icon(
              icon="UserIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
            ) {{ $t('changepassword') }}
          vs-divider(
            class="m-1"
          )
          li(
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          )
            feather-icon(
              icon="LogOutIcon"
              svgClasses="w-4 h-4"
            )
            span(
              class="ml-2"
              ) {{ $t('auth.logout') }}
</template>
<script>
import { UserPlusIcon } from "vue-feather-icons"
import { mapGetters } from "vuex"

export default {
  components: {
    UserPlusIcon
  },
  data() {
    return {
      user: null,
      permissions: [],
      isVisibleCompanySelection: true
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "currentRol"])
  },
  watch: {
    currentUser: {
      handler() {
        this.isVisibleCompanySelection = false
        setTimeout(() => {
          this.isVisibleCompanySelection = true
        }, 500)
      },
      deep: true
    }
  },
  methods: {
    getCompanyPermission(company) {
      return this.currentUser.groups.filter(permission => permission.company === company.id)
    },
    setCurrentCompany(company, permission) {
      let companyOrdered = []
      let company_user = this.currentUser.companies
      if (company_user.length > 0) {
        let company_id = company_user.map(({ id }) => id)
        let company_user_filter = company_id.filter(companyUser => companyUser != company.id)
        company_user_filter.unshift(company.id)
        companyOrdered = company_user_filter
      }
      let dataUser = {
        ...this.currentUser,
        country: null,
        company: companyOrdered,
        company_id: company,
        current_role_: permission.id,
        updateUser: true
      }
      this.$vs.loading({
        container: "#divMenuLogin",
        type: "material",
        scale: 1
      })
      this.$store
        .dispatch("auth/update_user", {
          payload: dataUser,
          this: this
        })
        .then(async () => {
          await Promise.allSettled([
            await this.$store.dispatch('config/setConfig'),
            await this.$store.dispatch('company/setCompany', dataUser.company_id.id)
          ])
          this.$vs.loading.close("#divMenuLogin > .con-vs-loading")
          this.$vs.notify({
            text: this.$t("user_save"),
            color: "#64cc52",
            iconPack: "feather",
            icon: "icon-check-circle"
          })
          this.$router.go()
        })
        .catch(() => {
          this.$vs.loading.close("#divMenuLogin > .con-vs-loading")
        })
    },
    setImage(val) {
      let pathname = null
      let validateUrl = null
      if (val) {
        validateUrl = val.split("/")
        let index = validateUrl.indexOf("media")
        if (validateUrl[0] == "http:" || validateUrl[0] == "https:") {
          pathname = new URL(val).pathname
        } else if (index == -1) {
          pathname = `/media/${validateUrl[0]}/${validateUrl[1]}`
        } else {
          pathname = val
        }
        return `${process.env.VUE_APP_URL_API}${pathname}`
      } else {
        return require("../../../assets/images/logo/defaultAvatar.png")
      }
    },
    logout() {
      this.$store.dispatch("auth/logout")
      this.$store.commit("auth/PURGE_AUTH")
      this.$swal({
        position: "center",
        type: "success",
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        title: this.$t("bye-plataform"),
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 1500
      }).then(result => {
        if (result) {
          this.$router.push({ name: "Login" })
        }
      })
    }
  }
}
</script>
