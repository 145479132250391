<template lang="pug">
  feather-icon.cursor-pointer.navbar-fuzzy-search.ml-4(
    icon='PlayIcon'
    style="color:#626262"
    v-on:click="startDemo"
  )
</template>
<script>
import { PlayIcon } from "vue-feather-icons"
import JwtService from "@/jwt-service"
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({isDemo: 'company/getDemo'}),
  },
  components: {
    PlayIcon
  },
  data() {
    return {
      isActivePopup: false
    }
  },
  methods: {
    startDemo() {
      this.$swal({
        position: "center",
        type: "warning",
        title: this.$t("nav_bar.start_demo"),
        text: this.$t("nav_bar.demo_description"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        confirmButtonText: this.$t("accept-text"),
        cancelButtonText: this.$t("cancel-text"),
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        customClass: {
          container: "newlineText"
        }
      }).then(result => {
        if (result.value) {
          let userInfo = JSON.parse(localStorage.getItem("userInfo"))
          if (this.isDemo) {
            this.$events.fire("activeBlockApplication", true)
            this.$socket.emit("generateDemoCompany", {
              token: JwtService.getToken(),
              data: { confirmed: true },
              company_id: userInfo.company_id.id
            })
          }
        }
      })
    }
  }
}
</script>
<style>
.newlineText #swal2-content {
  white-space: pre-line !important;
}
</style>
