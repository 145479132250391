<template lang="pug">
vs-dropdown.cursor-pointer(
  vs-custom-content
  vs-trigger-click
)
  span.cursor-pointer.flex.i18n-locale
    img.h-4.w-5(
      :src='require(`@/assets/images/flags/${this.$store.state.language.language}.png`)'
      :alt='this.$store.state.language.language'
    )
    span.hidden.ml-2(
      class='sm:block'
    ) {{ getCurrentLocaleData.lang }}
  vs-dropdown-menu.w-48.i18n-dropdown
    vs-dropdown-item(
      @click="updateLocale('es')"
    )
      img.h-4.w-5.mr-1(
        src='@/assets/images/flags/es.png'
        alt='es'
      )
      | Español
    vs-dropdown-item(
      @click="updateLocale('en')"
    )
      img.h-4.w-5.mr-1(
        src='@/assets/images/flags/en.png'
        alt='en'
      )
      | English
    //- vs-dropdown-item(@click="updateLocale('fr')")
    //-   img.h-4.w-5.mr-1(src='@/assets/images/flags/fr.png' alt='fr')
    //-   |  French
    //- vs-dropdown-item(@click="updateLocale('de')")
    //-   img.h-4.w-5.mr-1(src='@/assets/images/flags/de.png' alt='de')
    //-   |  German
    //- vs-dropdown-item(@click="updateLocale('pt')")
    //-   img.h-4.w-5.mr-1(src='@/assets/images/flags/pt.png' alt='pt')
    //-   |  Portuguese
</template>

<script>

export default {
  computed: {
    getCurrentLocaleData () {
      // const locale = this.$i18n.locale
      const locale = this.$store.state.language.language
      if (locale == "es") return { flag: "es", lang: "Español" }
      else if (locale == "en") return { flag: "en", lang: "English" }
      // else if (locale == "pt") return { flag: "br", lang: "Portuguese" }
      // else if (locale == "fr") return { flag: "fr", lang: "French" }
      // else if (locale == "de") return { flag: "de", lang: "German" }
    }
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale
      this.$store.dispatch('language/setLanguage', locale)
    }
  }
}
</script>

