<template lang="pug">
  vue-services
</template>

<script>

import ServicesActions from "@/components/towerControl/ServicesActions"

export default {
  components:{
    "vue-services": ServicesActions
  }
}
</script>
